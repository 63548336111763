import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { Box, Button, useMediaQuery } from '@mui/material';

import QRCode from './qrCode';

const ProductCarousel = forwardRef(function ProductCarousel(props, ref) {
  const { colorValue, product } = props;

  const [searchParams] = useSearchParams();
  const productId = searchParams.get('id');

  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const downMd = useMediaQuery(theme => theme.breakpoints.down('md'));

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <Box>
      <div
        className="slider-container"
        style={{
          width: '100%',
          margin: '0 auto',
          border: '1px solid #f2f2f2'
        }}
      >
        <Slider
          asNavFor={nav2}
          ref={slider => (sliderRef1 = slider)}
          {...settings}
        >
          <Box>
            {open ? (
              <QRCode
                handleClose={handleClose}
                colorValue={colorValue}
                productId={productId}
              />
            ) : (
              <Box position="relative">
                {!isMobile && (
                  <Button
                    sx={{
                      position: 'absolute',
                      top: 16,
                      left: 16,
                      borderRadius: 5,
                      px: 2,
                      background: '#eee',
                      color: '#0009',
                      '&:hover': {
                        background: '#eee',
                        color: '#0009'
                      }
                    }}
                    size="small"
                    startIcon={<ViewInArIcon />}
                    onClick={() => setOpen(!open)}
                  >
                    Abrir RA
                  </Button>
                )}

                <iframe
                  ref={ref}
                  src={`https://iframe.viewermodel.com/${productId}?color-1=${colorValue}`}
                  width="100%"
                  height="560px"
                  style={{ border: 'none' }}
                />
              </Box>
            )}
          </Box>

          <Box height={560} display="flex !important" alignItems="center">
            <Box
              component="img"
              src={product.thumbnail}
              width="100%"
              sx={{ my: 'auto' }}
            />
          </Box>
          {product.images.map((image, index) => (
            <Box
              key={'image' + index}
              height={560}
              display="flex !important"
              alignItems="center"
            >
              <Box
                component="img"
                src={image.url}
                width="100%"
                sx={{ my: 'auto' }}
              />
            </Box>
          ))}
        </Slider>
      </div>

      <Box mt={4} mb={downMd ? 4 : 0} width={320} mx="auto">
        <div className="slider-container">
          <Slider
            asNavFor={nav1}
            ref={slider => (sliderRef2 = slider)}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={false}
          >
            <div style={{ width: 96 }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderRadius: 1.2,
                  background: '#000',
                  width: '96px',
                  height: '70px',
                  cursor: 'pointer'
                }}
              >
                <svg
                  style={{ fontSize: '20px' }}
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 48 40"
                  width="48"
                  height="40"
                  fill="none"
                >
                  <path
                    d="M38.25 15.973V7.65253C38.25 7.35628 38.0752 7.08778 37.8045 6.96703L24.3045 0.967031C24.1102 0.880781 23.889 0.880781 23.6955 0.967031L10.1955 6.96703C9.92475 7.08778 9.75 7.35628 9.75 7.65253V15.973C3.54975 18.2358 0 21.7435 0 25.6525C0 32.0823 9.627 37.177 22.224 37.618L21.2197 38.6223C20.9265 38.9155 20.9265 39.3895 21.2197 39.6828C21.366 39.829 21.558 39.9025 21.75 39.9025C21.942 39.9025 22.134 39.829 22.2803 39.6828L24.5303 37.4328C24.8235 37.1395 24.8235 36.6655 24.5303 36.3723L22.2803 34.1223C21.987 33.829 21.513 33.829 21.2197 34.1223C20.9265 34.4155 20.9265 34.8895 21.2197 35.1828L22.149 36.112C10.7797 35.656 1.5 31.0533 1.5 25.6525C1.5 22.5588 4.563 19.5798 9.75 17.5705V25.6525C9.75 25.9488 9.92475 26.2173 10.1955 26.338L23.6955 32.338C23.7922 32.3808 23.8965 32.4025 24 32.4025C24.1035 32.4025 24.2077 32.3808 24.3045 32.338L37.8045 26.338C38.0752 26.2173 38.25 25.9488 38.25 25.6525V17.5705C43.4362 19.5798 46.5 22.558 46.5 25.6525C46.5 30.6183 38.9002 34.9443 28.4287 35.9388C28.0162 35.9778 27.714 36.3445 27.753 36.7563C27.7898 37.1448 28.1168 37.435 28.4993 37.435C28.5225 37.435 28.5465 37.4343 28.5713 37.432C40.0103 36.3453 48 31.5018 48 25.6525C48 21.7435 44.4503 18.2358 38.25 15.973ZM24 2.47303L35.6535 7.65253L24 12.832L12.3465 7.65253L24 2.47303ZM11.25 8.80678L23.25 14.14V30.499L11.25 25.165V8.80678ZM24.75 30.4983V14.1393L36.75 8.80603V25.165L24.75 30.4983Z"
                    fill="white"
                  ></path>
                </svg>
              </Box>
            </div>

            <div style={{ width: 96 }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderRadius: 1.2,
                  border: '1px solid #f2f2f2',
                  width: '96px',
                  height: '70px',
                  cursor: 'pointer'
                }}
              >
                <img width={64} src={product.thumbnail} />
              </Box>
            </div>

            {product.images.map((image, index) => (
              <div key={'second-carousel-image' + index} style={{ width: 96 }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    borderRadius: 1.2,
                    border: '1px solid #f2f2f2',
                    width: '96px',
                    height: '70px',
                    cursor: 'pointer'
                  }}
                >
                  <img width={64} src={image.url} />
                </Box>
              </div>
            ))}
          </Slider>
        </div>
      </Box>
    </Box>
  );
});

export default ProductCarousel;
