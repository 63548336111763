import React from 'react';

import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { Paragraph } from 'components/typography';
import { QRCodeSVG } from 'qrcode.react';

export default function QRCode(props) {
  const { handleClose, colorValue, productId } = props;

  return (
    <Box
      display="flex !important"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="560px"
      sx={{ backgroundColor: '#3f6778' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="calc(100% - 60px)"
        height="calc(100% - 60px)"
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '6px',
          padding: '8px'
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box />
          <IconButton
            sx={{ float: 'right', backgroundColor: '#eeeeee' }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          flexGrow={1}
          mb={4}
        >
          <QRCodeSVG
            value={`https://iframe.viewermodel.com/${productId}?color-1=${colorValue}`}
            size={220}
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'L'}
            includeMargin
          />

          <Box color="#4d4d4d" fontWeight={300}>
            <Box>
              <Paragraph>
                Aponte a câmera do seu smartphone ou tablet para escanear o
                código QR e ver este produto em seu aparelho.
              </Paragraph>
            </Box>

            <Box mt={2}>
              <Paragraph sx={{ fontSize: '12px' }}>
                Requisitos mínimos:
              </Paragraph>
              <Paragraph>
                iOS 13, iPadOS 13 ou Android com AR Core 1.9 ou superior
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
