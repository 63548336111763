import React from 'react';

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  IconButton,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import Brand from 'components/brand';
import MuiSearch from 'components/search';

const items = [
  'Todos Produtos',
  'Brindes',
  'Camisetas',
  'Lançamentos',
  'Garrafas'
];

export default function Header() {
  const [clickedItem, setClickedItem] = React.useState(5);

  const downMd = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Box>
      <Container maxWidth="xl">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={2}
        >
          {!downMd && <Brand fontSize={26} />}

          <Box
            display="flex"
            gap={downMd ? 1 : 2}
            justifyContent={downMd && 'space-between'}
            sx={{ width: downMd ? '100%' : 'auto' }}
          >
            {downMd ? (
              <Box display="flex" ml={-1}>
                <IconButton>
                  <MenuIcon />
                </IconButton>

                <Brand fontSize={26} />
              </Box>
            ) : (
              <Breadcrumbs aria-label="breadcrumb">
                <Tooltip title="Onde estamos?" arrow>
                  <IconButton>
                    <FmdGoodOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Ajuda" arrow>
                  <IconButton>
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Favoritos" arrow>
                  <IconButton>
                    <FavoriteBorderOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Button
                  variant="text"
                  startIcon={<PersonOutlineOutlinedIcon />}
                >
                  Minha conta
                </Button>
              </Breadcrumbs>
            )}

            <Button variant="outlined" startIcon={<ShoppingCartOutlinedIcon />}>
              Carrinho (0)
            </Button>
          </Box>
        </Box>
      </Container>

      <Divider />
      <Container maxWidth="xl">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {!downMd && (
            <Box display="flex" alignItems="baseline" gap={2}>
              {items.map((item, index) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  key={index}
                  sx={{
                    '&:hover .childBox': {
                      display: 'block !important'
                    }
                  }}
                >
                  <Button
                    variant="text"
                    sx={{ px: 0, '&:hover': { background: 'none' } }}
                    onClick={() => setClickedItem(index)}
                    disableRipple
                    disableFocusRipple
                  >
                    {item}
                  </Button>

                  <Box
                    width={24}
                    height={3}
                    sx={{
                      borderRadius: 0.5,
                      backgroundColor: 'black',
                      display: index === clickedItem ? 'block' : 'none'
                    }}
                    className="childBox"
                    mt={-0.9}
                  />
                </Box>
              ))}
            </Box>
          )}

          <Box
            sx={{
              width: downMd ? '100%' : 'auto',
              borderLeft: !downMd && '1px solid #e0e0e0',
              borderRight: !downMd && '1px solid #e0e0e0'
            }}
          >
            <MuiSearch placeholder="Pesquisar" fullWidth={downMd} />
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );
}
