import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  useMediaQuery
} from '@mui/material';
import { CustomSelect } from 'components/inputs';
import { H1, H2, H5, Paragraph, Small } from 'components/typography';

export default function ProductInfo(props) {
  const { colorValue, handleChangeColor, handleFileChange, product } = props;

  const downMd = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <H1 lineHeight={1.2} fontWeight={600}>
          {product.name}
        </H1>
      </Grid>

      <Grid item xs={12} sx={{ mt: downMd ? -1 : -2 }}>
        <H2 fontWeight={400}>R$ 1.146,25</H2>
      </Grid>

      <Grid item xs={12}>
        <Box mt={1} mb={0.5}>
          <Small>COR</Small>
        </Box>

        <CustomSelect fullWidth value={colorValue} onChange={handleChangeColor}>
          <MenuItem value="000000">
            <Box display="flex" alignItems="center">
              <Box backgroundColor="#000000" width={20} height={20} mr={2} />
              Preto
            </Box>
          </MenuItem>
          <MenuItem value="FF0000">
            <Box display="flex" alignItems="center">
              <Box backgroundColor="#FF0000" width={20} height={20} mr={2} />
              Vermelho
            </Box>
          </MenuItem>
          <MenuItem value="0000FF">
            <Box display="flex" alignItems="center">
              <Box backgroundColor="#0000FF" width={20} height={20} mr={2} />
              Azul
            </Box>
          </MenuItem>
          <MenuItem value="008000">
            <Box display="flex" alignItems="center">
              <Box backgroundColor="#008000" width={20} height={20} mr={2} />
              Verde
            </Box>
          </MenuItem>
        </CustomSelect>
      </Grid>

      {product.definitions.find(e => e.material === 'logomarca') && (
        <Grid item xs={12}>
          <Box mt={1} mb={0.5}>
            <Small>LOGOMARCA</Small>
          </Box>
          <TextField fullWidth type="file" onChange={handleFileChange} />
        </Grid>
      )}

      <Grid item xs={12}>
        <Box mt={1}>
          <Small>QUANTIDADE</Small>
        </Box>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <CustomSelect fullWidth value={1}>
              {Array.from({ length: 8 }, (_, i) => i + 1).map(value => (
                <MenuItem key={`qtd-value-${value}`} value={value}>
                  {value}
                </MenuItem>
              ))}
            </CustomSelect>
          </Grid>

          <Grid item xs>
            <Button
              fullWidth
              variant="contained"
              size="large"
              sx={{
                height: 56,
                fontWeight: 600,
                backgroundColor: '#000000',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#000000'
                }
              }}
            >
              Adicionar ao Carrinho
            </Button>
          </Grid>

          <Grid item xs="auto">
            <IconButton
              sx={{
                borderRadius: '4px',
                width: 56,
                height: 56,
                border: '1px solid #eeeeee'
              }}
            >
              <FavoriteBorderOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <H5 fontWeight={600}>Detalhes</H5>
          </AccordionSummary>
          <AccordionDetails sx={{ py: 2 }}>
            <Paragraph color="#64748b" fontSize="16px">
              {product.description}
            </Paragraph>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <H5 fontWeight={600}>Medidas</H5>
          </AccordionSummary>
          <AccordionDetails>
            <Paragraph color="#64748b" fontSize="16px">
              Altura: 12.8cm
            </Paragraph>
            <Paragraph color="#64748b" fontSize="16px">
              Largura: 13.5cm
            </Paragraph>
            <Paragraph color="#64748b" fontSize="16px">
              Circunferência: 31.5cm
            </Paragraph>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <H5 fontWeight={600}>Entrega</H5>
          </AccordionSummary>
          <AccordionDetails>
            <Paragraph color="#64748b" fontSize="16px">
              Em estoque. Receba o seu produto em até 15 (quinze) dias úteis.
            </Paragraph>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <H5 fontWeight={600}>Garantia</H5>
          </AccordionSummary>
          <AccordionDetails>
            <Paragraph color="#64748b" fontSize="16px">
              90 dias contados a partir do dia de recebimento. Garantia básica
              contra defeitos de fabricação e mau funcionamento. Não cobrimos
              danos por uso inadequado, desgaste natural, acidentes, ou reparos
              não autorizados. Para acionar a garantia, entre em contato com
              nosso atendimento ao cliente com o número do pedido e uma
              descrição do problema.
            </Paragraph>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
