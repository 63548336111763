import { alpha, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Satoshi', sans-serif"
  },
  palette: {
    action: {
      hover: alpha('#000000', 0.08)
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'inherit'
        },
        outlined: {
          borderColor: 'rgba(0, 0, 0, 0.2)'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '20px'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
          textDecoration: 'none'
        }
      }
    }
  }
});

export default theme;
