import React from 'react';

import { Box, Container, CircularProgress } from '@mui/material';

export default function LoadingScreen() {
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          '& div': {
            textAlign: 'center',
            lineHeight: '40px'
          }
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
}
