import React, { useRef, useState } from 'react';

import { Box, Breadcrumbs, Divider, Grid } from '@mui/material';
import { Small } from 'components/typography';

import ProductCarousel from './productCarousel';
import ProductInfo from './productInfo';
import Reviews from './reviews';

export default function Body(props) {
  const { product } = props;

  const iframeRef = useRef(null);

  const [colorValue, setColorValue] = useState('000000');

  const handleChangeColor = e => {
    setColorValue(e.target.value);
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;

        iframeRef.current.contentWindow.postMessage(
          { text: base64String },
          '*'
        );
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box mt={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Small
          fontWeight={400}
          sx={{ '&:hover': { color: 'black' }, cursor: 'pointer' }}
        >
          Início
        </Small>
        <Small
          fontWeight="bold"
          color="text.default"
          sx={{ '&:hover': { color: 'black' }, cursor: 'pointer' }}
        >
          Brindes
        </Small>
      </Breadcrumbs>

      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <ProductCarousel
            ref={iframeRef}
            colorValue={colorValue}
            product={product}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ProductInfo
            colorValue={colorValue}
            handleChangeColor={handleChangeColor}
            handleFileChange={handleFileChange}
            product={product}
          />
        </Grid>
      </Grid>

      <Box mt={8} />

      <Divider />

      <Reviews />
    </Box>
  );
}
