import React, { Fragment, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useSearchParams
} from 'react-router-dom';

import { Container } from '@mui/material';
import Body from 'containers/body';
import Header from 'containers/header';
import LoadingScreen from 'containers/loading';
import NotFound from 'containers/notFound';

function ProductPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [product, setProduct] = useState(null);

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    const productId = searchParams.get('id');

    fetch(
      `https://us-central1-wapper-io.cloudfunctions.net/api/products/info/${productId}`
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }

        throw new Error('Produto não encontrado');
      })
      .then(data => {
        setProduct(data.product);
      })
      .catch(error => {
        console.error(error.message);
        navigate('/404');
      });
  };

  return product ? (
    <Fragment>
      <Header />

      <Container maxWidth="xl" mb={4}>
        <Body product={product} />
      </Container>
    </Fragment>
  ) : (
    <LoadingScreen />
  );
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/products" element={<ProductPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
