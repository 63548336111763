import React from 'react';

import { Box, useMediaQuery } from '@mui/material';

import { H1 } from '../typography';

export default function Brand(props) {
  const { cursor, fontSize } = props;

  const downMd = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ cursor: cursor || 'context-menu' }}
    >
      <H1 fontWeight={900} fontSize={fontSize || '28px'}>
        {downMd ? 'Veforma' : 'Veforma'}
      </H1>
    </Box>
  );
}
