import React from 'react';

import { Select, TextField, useTheme } from '@mui/material';

export const CustomTextField = props => {
  const { name, type, label, value, size, ...rest } = props;

  const theme = useTheme();

  return (
    <TextField
      {...rest}
      type={type || 'text'}
      label={label}
      value={value}
      size={size || 'medium'}
      sx={{
        '&:hover, & .MuiOutlinedInput-root:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff'
          }
        },
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#00000036'
        }
      }}
    />
  );
};

export const CustomSelect = props => {
  const { name, type, label, value, ...rest } = props;

  return (
    <Select
      {...rest}
      value={value}
      sx={{
        '& .MuiSelect-select': {
          backgroundColor: '#fff'
        }
      }}
    />
  );
};
