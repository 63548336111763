import React from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { InputBase, useMediaQuery } from '@mui/material';

export default function MuiSearch(props) {
  const { search, ...rest } = props;

  const downMd = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      search();
    }
  };

  return (
    <InputBase
      {...rest}
      sx={{
        height: 50,
        fontSize: 14,
        fontWeight: 500,
        padding: '0 4px',
        paddingLeft: 2,
        borderRadius: '2px',
        borderColor: theme => theme.palette.divider,
        color: theme => theme.palette.text.primary,
        backgroundColor: theme =>
          theme.palette.mode === 'light' ? '#fff' : '#00000036',
        [downMd]: {
          maxWidth: '100%'
        }
      }}
      onKeyDown={handleKeyPress}
      endAdornment={
        <SearchOutlinedIcon
          onClick={search}
          sx={{
            fontSize: 16,
            marginLeft: 2,
            marginRight: 1,
            cursor: 'pointer',
            color: theme =>
              theme.palette.mode === 'light'
                ? theme.palette.secondary[300]
                : theme.palette.text.disabled
          }}
        />
      }
    />
  );
}
