/* eslint-disable no-unused-vars */
import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Divider, Rating, useMediaQuery } from '@mui/material';
import { green } from '@mui/material/colors';
import { H2, H3, H5, Paragraph, Small, Tiny } from 'components/typography';

export default function Reviews() {
  const downMd = useMediaQuery(theme => theme.breakpoints.down('md'));

  const reviews = [
    {
      title: 'Excelente Qualidade e Design Moderno',
      name: 'Mariana S.',
      date: '25 de julho de 2024',
      text: 'Estou extremamente satisfeita com este produto! O design é um diferencial incrível e a qualidade supera as expectativas. Recomendo!'
    },
    {
      title: 'Perfeito para o Uso Diário',
      name: 'Carlos O.',
      date: '30 de julho de 2024',
      text: 'Comprei este item para usar no meu dia a dia e não poderia estar mais feliz. A praticidade e funcionalidade são ótimas. Produto de excelente qualidade!'
    },
    {
      title: 'Ótimo para Quem Está Sempre em Movimento',
      name: 'Fernanda C.',
      date: '15 de agosto de 2024',
      text: 'Uso este produto regularmente e ele nunca me decepciona. É ideal para quem precisa de algo confiável e durável. Super recomendo!'
    }
  ];

  return (
    <Box mt={4}>
      <Box mb={2}>
        <H3 fontWeight={500}>AVALIAÇÕES DO PRODUTO</H3>
      </Box>

      <Box
        display="flex"
        alignItems="baseline"
        flexDirection={downMd ? 'column' : 'row'}
        gap={4}
      >
        <Box>
          <Box display="flex" alignItems="center">
            <H2 fontWeight={500} mr={1}>
              5.0
            </H2>
            <Rating value={5} readOnly size="large" sx={{ color: 'black' }} />
          </Box>

          <Box mt={1}>
            <Paragraph fontWeight={500}>Baseado em 5 avaliações</Paragraph>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" alignItems="center" gap={2}>
            <Rating value={5} readOnly size="large" sx={{ color: 'black' }} />
            <Paragraph fontWeight={500}>(3)</Paragraph>
            <Box width={160} height={14} sx={{ backgroundColor: 'black' }} />
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <Rating value={0} readOnly size="large" sx={{ color: 'black' }} />
            <Paragraph fontWeight={500}>(0)</Paragraph>
            <Box width={160} height={14} sx={{ backgroundColor: '#e0e0e0' }} />
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <Rating value={0} readOnly size="large" sx={{ color: 'black' }} />
            <Paragraph fontWeight={500}>(0)</Paragraph>
            <Box width={160} height={14} sx={{ backgroundColor: '#e0e0e0' }} />
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <Rating value={0} readOnly size="large" sx={{ color: 'black' }} />
            <Paragraph fontWeight={500}>(0)</Paragraph>
            <Box width={160} height={14} sx={{ backgroundColor: '#e0e0e0' }} />
          </Box>

          <Box display="flex" alignItems="center" gap={2}>
            <Rating value={0} readOnly size="large" sx={{ color: 'black' }} />
            <Paragraph fontWeight={500}>(0)</Paragraph>
            <Box width={160} height={14} sx={{ backgroundColor: '#e0e0e0' }} />
          </Box>
        </Box>
      </Box>

      {reviews.map((review, index) => (
        <Box key={`review-${index}`}>
          <Box my={4}>
            <Box display="flex" alignItems="center">
              <Rating
                value={5}
                readOnly
                size="large"
                sx={{ color: 'black', mr: 2 }}
              />
              <Paragraph fontWeight={600}>{review.title}</Paragraph>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <CheckCircleIcon
                style={{ color: green[600], fontSize: '14px' }}
              />
              <Tiny ml={1} color="#64748b">
                Avaliado por {review.name} - {review.date}
              </Tiny>
            </Box>

            <Box mt={1}>
              <Paragraph>{review.text}</Paragraph>
            </Box>
          </Box>

          {index < reviews.length - 1 && <Divider />}
        </Box>
      ))}
    </Box>
  );
}
