import React from 'react';

import { Box, styled } from '@mui/material';
import { H1, Paragraph } from 'components/typography';

export default function NotFound() {
  return (
    <Content>
      <Box>
        <Wrapper>
          <H1>404</H1>
          <Paragraph color="text.disabled">
            Oops! Página não encontrada.
          </Paragraph>
        </Wrapper>
      </Box>
    </Content>
  );
}

const Content = styled('div')(() => ({
  textAlign: 'center',
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const Wrapper = styled(Box)(({ theme }) => ({
  '& > h1': {
    fontWeight: '700',
    color: '#5024D1',
    fontSize: '200px',
    lineHeight: '200px',
    margin: 0
  },
  '& > p': {
    fontSize: '22px'
  },
  [theme.breakpoints.down('md')]: {
    '& > p': {
      fontSize: '18px'
    },
    '& > h1': {
      fontSize: '150px'
    }
  }
}));
